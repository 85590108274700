<template>
  <div>
    <b-overlay
      spinner-variant="danger"
      variant="dark"
      z-index="900"
      fixed
      :show="generatingPdf"
      no-wrap
    />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="SalesReport"
      :pdf-quality="1"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      @progress="onProgress"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-3">
        <sales-report-export :items="exportItems" />
      </section>
    </vue-html2pdf>

    <div class="card">
      <div
        class="card-body text-center"
        v-if="selectPickerDataLoading || allBranchesLoading"
      >
        <b-icon
          icon="three-dots"
          font-scale="8"
          animation="cylon"
          class="mb-4"
        ></b-icon>
      </div>
      <div class="card-body" v-else>
        <div class="d-flex mb-4" v-if="selectedBranch">
          <b-button
            class="mr-3 mb-5"
            variant="info"
            @click.stop="generatePDF"
            >{{ $t("PAGES.SALES_MANAGEMENT.LIST.EXPORT_TO_PDF") }}</b-button
          >
          <JsonExcel
            v-for="mimeType in ['xls', 'csv']"
            :key="mimeType"
            :name="`SalesReport.${mimeType}`"
            :fetch="() => generateExcel(mimeType)"
            :fields="excelFields"
            :type="mimeType"
            :before-generate="
              () => {
                generatingPdf = true;
              }
            "
            :before-finish="
              () => {
                generatingPdf = false;
              }
            "
          >
            <b-button
              class="mr-3 mb-5"
              :variant="mimeType === 'xls' ? 'success' : 'primary'"
            >
              {{
                mimeType === "xls"
                  ? $t("PAGES.SALES_MANAGEMENT.LIST.EXPORT_TO_EXCEL")
                  : "CSV"
              }}</b-button
            >
          </JsonExcel>
        </div>
        <div class="row align-items-center mb-2">
          <div class="col-md-8 col-sm-12">
            <DatePickers
              :filters="filters"
              @option-selected="onOptionSelected"
              @on-start-date-selected="onStartDateSelected"
              @on-end-date-selected="onEndDateSelected"
              @on-all-dates-selected="onAllDatesSelected"
            >
              <template v-slot:filters v-if="showStatusFilter">
                <list-filter
                  v-for="(filter, filterIndex) in filters"
                  :key="filterIndex"
                  :filter="filter"
                  :filter-index="filterIndex"
                  @option-selected="onOptionSelected"
                />
              </template>
            </DatePickers>
          </div>
          <div class="col-md-4 col-sm-12" v-if="isMerchant">
            {{ $t("ROUTES.BRANCH") }}
            <b-form-select
              @change="onBranchSelected"
              v-model="selectedBranch"
              :options="merchantBranches"
              text-field="title"
              value-field="branch"
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null">{{
                  $t("FORM.PLEASE_SELECT")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="row align-items-center mb-2" v-if="isDistributor">
          <div class="col-md-6 col-sm-12">
            {{ $t("PAGES.SALES_MANAGEMENT.LIST.LEVELS.MERCHANT") }}
            <b-form-select
              @change="onMerchantSelected"
              v-model="selectedMerchant"
              :options="allMerchants"
              text-field="title"
              value-field="merchant"
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null">{{
                  $t("FORM.PLEASE_SELECT")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col-md-6 col-sm-12">
            {{ $t("PAGES.SALES_MANAGEMENT.LIST.LEVELS.BRANCH") }}
            <b-form-select
              @change="onBranchSelected"
              v-model="selectedBranch"
              :options="allBranches"
              text-field="title"
              value-field="id"
            >
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null">{{
                  $t("FORM.PLEASE_SELECT")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>
        <div class="row align-items-center mb-4">
          <div class="col-md-8 col-sm-12">
            &nbsp;
            <TableFilter
              @filter-change="onFilterChange"
              :searchPlaceHolder="$t('ECOMMERCE.COMMON.FILTER')"
            />
          </div>

          <div class="col-md-4 col-sm-12">
            {{ $t("ECOMMERCE.COMMON.FILTER") }}
            <b-form-select
              v-model="filterIncludedFields"
              :options="filterIncludedFieldsArray"
              text-field="text"
              value-field="field"
            >
            </b-form-select>
          </div>
        </div>

        <b-table
          ref="listTable"
          id="orders-table"
          primary-key="id"
          :items="fetchData"
          :fields="fields"
          :filter="filter"
          selectable
          select-mode="single"
          :busy.sync="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          @refreshed="onRefreshed"
          @row-selected="onRowSelected"
          :responsive="true"
          stacked="md"
        >
          <template #table-busy>
            <div class="text-center text-danger my-5">
              <b-spinner class="align-middle busy-spinner"></b-spinner>
            </div>
          </template>

          <template #cell(nexusAmount)="data">
            {{ getPaidCryptoQuantity(data.item) }}
          </template>
          <template #cell(price)="data">
            {{ data.value }}
          </template>
          <template #cell(paymentUnitName)="data">
            {{ data.value }}
            <!-- {{ getPaymentUnitName(data.item) }} -->
          </template>
          <template #cell(fiat)="data">
            {{ data.value ? "Fiat" : "Crypto" }}
          </template>
          <template #cell(selectedCurrencyCode)="data">
            {{ data.item.selectedCurrencyCode }}
            <!--            {{ data.value }}-->
          </template>

          <template #cell(fee)="data">
            <span class="d-inline-flex align-items-center">
              {{ data.value }}
              <b-icon
                icon="check"
                font-scale="2"
                class="common-icon-color"
                variant="success"
                v-if="data.item.feeIncluded"
              ></b-icon>
              <b-icon
                icon="x"
                v-else
                font-scale="2"
                class="common-icon-color"
              ></b-icon>
            </span>
          </template>

          <template #cell(orderTotal)="data">
            {{ data.value }}
          </template>

          <template #cell(orderStatus)="data">
            <span class="d-inline-flex align-items-center">
              <span
                class="badge mr-1"
                :class="[getOrderBadgeAndLabel(data.item.status).itemClass]"
              >
                {{ getOrderBadgeAndLabel(data.item.status).itemLabel }}
              </span>
            </span>
          </template>
          <template #cell(platform)="data">
            {{ getPlatform(data.item) }}
          </template>

          <template #cell(paymentStateName)="data">
            <span class="d-inline-flex align-items-center">
              <span
                class="badge mr-1"
                :class="[paymentStateClass(data.item.paymentStateId)]"
              >
                {{
                  $t(
                    `PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATES[${data.item.paymentStateId}]`
                  )
                }}
              </span>
            </span>
          </template>
          <template #cell(refundBtnAction)="data">
            <span
              class="badge badge-danger"
              @click.stop="onClickRefund(data.item)"
              v-if="showRefundButton(data.item)"
            >
              {{ $t("PAGES.SALES_MANAGEMENT.LIST.REFUND_PAYMENT") }}
            </span>
          </template>
        </b-table>

        <div class="row align-items-center mt-5">
          <h6 class="col-12">
            {{
              $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMPLETED_ORDERS_AMOUNT")
            }}
          </h6>
          <div class="col-12 align-items-center">
            <div
              v-for="(orderTotalItem, idx) in ordersTotal"
              :key="`orderTotal-${idx}-${orderTotalItem.id}`"
              class="d-flex flex-row justify-content-between"
            >
              <span class="col-4">{{ orderTotalItem.name }}</span>
              <span class="col-4">{{ orderTotalItem.orderTotal }}</span>
              <span class="col-4"
                >({{
                  $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDERS_COUNT", {
                    count: orderTotalItem.ordersCount
                  })
                }})</span
              >
            </div>
          </div>
          <div class="mt-5 col-12 pt-5">
            <b-pagination
              align="fill"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="orders-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- Refund modal -->
    <b-modal
      v-model="refundModalVisible"
      id="refundModal"
      centered
      hide-footer
      modal-class="body-class"
      @hide="onRefundModalHide"
    >
      <template #modal-header="{ close }">
        <h5 class="m-0 w-100 text-dark w-100 font-weight-bolder">
          {{ $t("PAGES.SALES_MANAGEMENT.LIST.REFUND_PAYMENT") }}
        </h5>
        <button
          type="button"
          aria-label="Close"
          @click.stop="close()"
          class="close text-dark font-weight-bolder"
        >
          ×
        </button>
      </template>
      <b-container
        fluid="sm"
        class="h-75 justify-content-center d-flex flex-column"
      >
        <b-icon
          class="align-self-center mb-2"
          icon="exclamation-triangle-fill"
          variant="danger"
          font-scale="3"
        ></b-icon>
        <p class="text-center mb-5">
          {{ $t("PAGES.SALES_MANAGEMENT.LIST.REFUND_MODAL_INFO") }}
        </p>
        <p class="p-0 m-0">
          {{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.WALLET") }}:
        </p>
        <div class="d-flex flex-row align-items-center w-100 mb-5">
          <div role="group" class="w-75">
            <b-form-input
              id="refundWalletAddress"
              v-model="refundWalletAddress"
              aria-describedby="input-live-help input-live-feedback"
              trim
            ></b-form-input>
          </div>
          <qr-code-reader
            v-on:qrcode-scanned="onQrCodeScanned"
            :show-camera-icon="true"
            :btn-variant="'primary'"
            :container-class="'ml-1 w-25'"
            :camera-icon-variant="'light'"
          />
        </div>
        <b-button
          variant="primary"
          pill
          :disabled="!refundWalletAddress || isSubmitting"
          block
          @click.stop="refundOrder"
          :class="{ 'spinner spinner-white spinner-right': isSubmitting }"
          >{{ $t("FORM.SUBMIT") }}</b-button
        >
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import TableFilter from "@/components/list/TableFilter.vue";
import DatePickers from "@/components/list/DatePickers.vue";
import { ListMixin } from "@/mixins/list.mixin";
import SalesReportExport from "@/components/list/SalesReportExport.vue";
import JsonExcel from "vue-json-excel";
import { mapGetters } from "vuex";
import QrCodeReader from "@/components/QrCode/QrCodeReader.vue";
import { FILTER_TYPE } from "@/constants";
import ListFilter from "@/components/list/ListFilter.vue";

export default {
  components: {
    TableFilter,
    DatePickers,
    VueHtml2pdf,
    SalesReportExport,
    JsonExcel,
    QrCodeReader,
    ListFilter
  },
  mixins: [ListMixin],
  data() {
    return {
      filterIncludedFieldsArray: [
        {
          text: this.$t("PAGES.SALES_MANAGEMENT.LIST.ORDER_ID"),
          field: "orderNumber"
        },
        {
          text: this.$t("PAGES.SALES_MANAGEMENT.LIST.SELECTED_CURRENCY_CODE"),
          field: "selectedCurrencyCode"
        },
        {
          text: this.$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER"),
          field: "platform"
        },
        {
          text: this.$t("PAGES.SALES_MANAGEMENT.LIST.PAYMENT_UNIT_NAME"),
          field: "paymentUnit.name"
        }
      ],
      filterIncludedFields: "orderNumber",
      generatingPdf: false,
      merchantBranches: [],
      selectedBranch: null,
      exportItems: [],
      filters: [
        /* {
          text: "Fiat",
          field: "fiat",
          type: FILTER_TYPE.FIAT,
          currentValue: undefined
        }, */
        {
          text: "PAGES.SALES_MANAGEMENT.LIST.STATUS",
          field: "status",
          type: FILTER_TYPE.ORDER_STATUS,
          currentValue: undefined
        }
      ],
      fields: [
        {
          key: "_id",
          label: "ID",
          sortable: true
        },
        {
          key: "orderNumber",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.ORDER_ID"),
          sortable: true
        },
        {
          key: "selectedCurrencyCode",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CRYPTO_CURRENCY_CODE")
        },
        {
          key: "amount",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CRYPTO_QUANTITY")
        },
        {
          key: "nexusAmount",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CRYPTO_QUANTITY_PAID")
        },
        {
          key: "paymentUnitName",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.PAYMENT_UNIT")
        },
        {
          key: "price",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.PRICE"),
          sortable: true
        },
        {
          key: "fee",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.COMMISSION"),
          sortable: true
        },
        {
          key: "commissionConvertCurrency",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.SERVICE_COMMISSION"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "orderTotal",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.TOTAL")
        },
        /* {
          key: "fiat",
          label: "Fiat"
        }, */
        {
          key: "createdAt",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.DATE"),
          sortable: true,
          formatter: value => {
            return this.$dateTimeFilter(value, "date-time");
          }
        },
        {
          key: "platform",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER"),
          sortable: true
        },
        {
          key: "orderStatus",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.STATUS"),
          sortable: true
        },
        /* {
          key: "paymentStateName",
          label: this.$t("PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATE"),
          sortable: true
        }, */
        {
          key: "refundBtnAction",
          label: " ",
          class: "text-center"
        }
      ],
      excelFields: {},
      excelType: "xls",
      ordersTotal: [],
      selectPickerDataLoading: false,
      refundModalVisible: false,
      refundWalletAddress: "",
      selectedRefundOrderId: null,
      isSubmitting: false,
      allMerchants: [],
      selectedMerchant: null,
      allBranchesLoading: false,
      allBranches: [],
      showStatusFilter: true,
      fixedFilters: null
    };
  },
  mounted() {
    this.changeStatusAccordingly(this.$route.name);
    if (this.isMerchant) {
      this.getAllBranchMerchants();
    }
    if (this.isDistributor) {
      this.getAllMerchants();
    }
  },
  computed: {
    ...mapGetters({
      isMerchant: "isMerchant",
      isDistributor: "isDistributor"
    }),
    fetchUrl() {
      return `companies/${this.selectedBranch}/company-orders`;
    }
  },
  methods: {
    async getAllBranchMerchants() {
      this.selectPickerDataLoading = true;
      try {
        const res = await this.$store.dispatch(
          "company/GET_ALL_BRANCH_MERCHANT"
        );
        const firstBranch = res.data?.data?.[0]?.branch || null;
        this.merchantBranches = res.data?.data || [];
        this.selectedBranch = firstBranch;
        this.fetchOrdersTotals(firstBranch);
        this.selectPickerDataLoading = false;
      } catch (error) {
        this.selectPickerDataLoading = false;
        console.log("Branch-Report-List@getAllBranchMerchants error: ", error);
      }
    },
    async getAllMerchants() {
      this.selectPickerDataLoading = true;
      try {
        const res = await this.$store.dispatch("company/GET_ALL_MERCHANT");
        const firstMerch = res.data?.data?.[0]?.merchant || null;
        this.allMerchants = res.data?.data || [];
        this.selectedMerchant = firstMerch;
        this.getAllBranches(firstMerch);
        this.selectPickerDataLoading = false;
      } catch (error) {
        this.selectPickerDataLoading = false;
        console.log("Branch-Report-List@getAllMerchants error: ", error);
      }
    },
    async getAllBranches(merchant) {
      this.allBranchesLoading = true;
      try {
        const res = await this.$store.dispatch(
          "company/GET_ALL_BRANCH_WITH_MERCHANT",
          merchant
        );
        const firstBranch = res.data?.[0]?.id || null;
        this.allBranches = res?.data || [];
        this.selectedBranch = firstBranch;
        this.fetchOrdersTotals(firstBranch);
        this.allBranchesLoading = false;
      } catch (error) {
        this.allBranchesLoading = false;
        console.log("Branch-Report-List@getAllBranches error: ", error);
      }
    },
    onRefreshed() {
      this.$refs.listTable.refresh();
    },
    onBranchSelected() {
      if (!this.selectedBranch) {
        return;
      }
      this.$refs.listTable.refresh();
      this.fetchOrdersTotals(this.selectedBranch);
    },
    onMerchantSelected() {
      if (!this.selectedMerchant) {
        return;
      }
      this.getAllBranches(this.selectedMerchant);
    },
    async fetchOrdersTotals(branchId) {
      try {
        const ordersTotals = await this.$store.dispatch(
          "orders/GET_ORDERS_TOTAL",
          branchId
        );
        // console.log(ordersTotals);
        this.ordersTotal = ordersTotals;
      } catch (error) {
        console.log("fetchOrdersTotals error: ", error);
      }
    },
    onClickRefund(item) {
      const orderId = item.id || item._id;
      if (!orderId) {
        this.$root.$bvToast.toast(this.$t("FORM.ERROR"), {
          title: "",
          variant: "danger",
          autoHideDelay: 5000
        });
        return;
      }
      this.selectedRefundOrderId = orderId;
      this.refundModalVisible = true;
    },
    async refundOrder() {
      if (!this.selectedRefundOrderId && !this.refundWalletAddress) {
        return;
      }
      const formData = {
        id: this.selectedRefundOrderId,
        walletAddress: this.refundWalletAddress
      };
      this.isSubmitting = true;
      try {
        await this.$store.dispatch("orders/GIVE_BACK_ORDER", formData);
        this.$root.$bvToast.toast(this.$t("COMMON.operation_performed"), {
          title: this.$t("FORM.SUCCESS_TITLE"),
          variant: "success",
          autoHideDelay: 5000
        });
        this.refundModalVisible = false;
        this.isSubmitting = false;
        this.$refs.listTable.refresh();
      } catch (error) {
        this.isSubmitting = false;
        this.$root.$bvToast.toast(this.$t("FORM.ERROR"), {
          title: "",
          variant: "danger",
          autoHideDelay: 5000
        });
      }
    },
    onQrCodeScanned(qrCode) {
      if (qrCode) {
        this.refundWalletAddress = qrCode;
      }
    },
    onRefundModalHide() {
      this.selectedRefundOrderId = null;
      this.refundWalletAddress = "";
    },
    showRefundButton(item) {
      return (
        this.isMerchant &&
        [1, 5].includes(item.status) &&
        item.platform === "Other"
      );
    },
    changeStatusAccordingly(routeName) {
      if (routeName === "refunded-sales-list") {
        this.showStatusFilter = false;
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: this.$t("ROUTES.REFUNDED_SALES") }
        ]);
        this.fixedFilters = "status=6";
      } else {
        this.showStatusFilter = true;
        this.$store.dispatch(SET_BREADCRUMB, [
          { title: this.$t("ROUTES.MERCHANT_BRANCH_REPORT") }
        ]);
        this.fixedFilters = null;
      }
      this.$refs.listTable.refresh();
    },
    onRowSelected(item) {
      /* this.$router.push({
        name: "merchant-order-details",
        params: { orderID: item[0]._id || item[0].id }
      }); */
      const orderDetailsRoute = this.$router.resolve({
        name: "merchant-order-details",
        params: { orderID: item[0]._id || item[0].id }
      });
      window.open(orderDetailsRoute.href, "_blank");
    }
  },
  watch: {
    $route: function(newVal, oldVal) {
      if (newVal.name !== oldVal.name) {
        this.changeStatusAccordingly(newVal.name);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.busy-spinner {
  width: 3rem;
  height: 3rem;
  margin: 5rem 0;
}
</style>
